.footerThree {
  background-color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .footerThree-container {
    display: flex;
    max-width: 1300px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    @media #{$sm-layout} {
        justify-content: flex-start;
      }

    .footerThree-logo {
      display: flex;
      flex-direction: column;
      width: 30%;
      gap: 20px;
      padding: 20px;
      //   min-width: 300px;

      @media #{$smlg-device} {
      }
      @media #{$md-layout} {
        width: 100%;
      }
      @media #{$sm-layout} {
        width: 100%;
      }
      img {
        width: 50px;
      }
      p {
        font-size: 16px;
        text-align: justify;
        margin-bottom: 0px;
      }
    }
    .footerThree-menu {
      // background: red;
      display: flex;
      gap: 20px;
      width: 20%;
      min-width: 200px;
      padding: 0 10px;
      @media #{$smlg-device} {
      }
      @media #{$md-layout} {
        gap: 10px;
        width: 30%;
      }
      @media #{$sm-layout} {
        min-width: max-content;
        padding: 0 20px;
        width: 100%;
      }
      @media #{$large-mobile} {
        min-width: max-content;
        width: 100%;
        padding: 0 20px;
      }
      @media #{$small-mobile} {
        min-width: max-content;
        width: 100%;
      }

      .footerThree-menu-border {
        .vertical-line {
          margin-left: 5px;
          height: 100%;
          width: 1px;
          background-color: var(--color-body);
          //   @media #{$large-mobile} {
          //     display: none;
          //   }
        }

        .circle {
          width: 10px;
          height: 10px;
          background-color: var(--color-body);
          border-radius: 50%;
          //   @media #{$large-mobile} {
          //     display: none;
          //   }
        }
      }

      .footermenu-nav {
        padding-top: 40px;
        @media #{$md-layout} {
          padding-top: 0px;
        }
        .footermenu {
          list-style: none;
          padding: 0px;
          li {
            a {
              width: 100%;
              font-size: 16px;
              color: var(--color-body);
              padding: 5px;
              &:hover {
                background-color: var(--color-body);
                color: var(--color-primary);
              }
            }
          }
        }
        .footermenu-icons {
          display: flex;
          flex-direction: row;
          list-style: none;
          padding: 0px;
          gap: 10px;
          li {
            a {
              font-size: 24px;
              color: var(--color-body);
              padding: 5px;
              // &:hover {
              //   border-radius: 10px;
              //   background-color: var(--color-body);
              //   color: var(--color-primary);
              // }
            }
          }
        }
      }
    }
  }
  .copyrights {
    width: 100%;
    max-width: 1300px;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    p {
      margin: 0px;
      font-size: 14px;
    }
  }
}
