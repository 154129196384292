.sliderBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* CustomSwiperArrows.css */

/* Customize next arrow */
.swiper-button-next,.swiper-button-prev {
  background-color: #f1f1f1;
  border-radius: 50%;
  height: 30px;
  width: 30px;

}

.swiper-button-next::after,.swiper-button-prev::after{
    display: flex;
    color: #707070;
    justify-content: center;
    align-items: center;
  font-size: 20px;
}

.sliderContainer {
  max-width: 1400px;
  color: var(--color-black) !important;
  width: 100%;
  padding: 50px 40px;
  display: flex;
  gap: 20px;

  border-bottom: 1px solid black;

  @media #{$large-mobile} {
    flex-direction: column;
    padding: 50px 20px;
  }

  .sliderContent {
    max-width: 300px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    text-align: justify;

    @media #{$large-mobile} {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }

    h2 {
      color: var(--color-primary);
      margin: 0px;
    }
    p {
      font-size: 14px;
      margin: 0px;
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  //   background: #861818;
  border-radius: 40px;
  height: 400px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 900;
}

// .swiper-slide img {
//   display: block;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }

.swiper {
  margin-left: auto;
  margin-right: auto;
}
