.contact-us-section {
  width: 100%;
  display: flex;
  min-height: 650px;
  .left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media #{$sm-layout} {
      width: 100%;
    }

    .content {
      max-width: 600px;
      @media #{$sm-layout} {
        width: 100%;
      }

      .content-form {
        max-width: 400px;
        padding: 20px 20px;
        h5 {
          margin: 0 0 5px 0;
        }
        p {
          margin: 0 0 40px 0;
        }

        @media #{$sm-layout} {
          max-width: 100%;
        }

        .contact-details {
          color: var(--color-text-dark);
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 20px;
          margin-left: 20px;
          .detail {
            display: flex;
            align-items: center;
            gap: 20px;
            font-size: 24px;
            color: var(--color-primary);
            p {
              margin: 0px;
              font-size: var(--font-body-1);
            }
          }
        }
      }
    }
  }
  .right {
    background-color: #f5f5f5;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$sm-layout} {
      width: 100%;
    }

    .content {
      max-width: 600px;
      @media #{$sm-layout} {
        width: 100%;
      }

      .content-form {
        padding: 20px 20px;
        max-width: 400px;
        @media #{$sm-layout} {
          max-width: 100%;
        }
      }
    }
  }
  @media #{$sm-layout} {
    flex-direction: column;
  }
}

.form-heading {
  font-size: var(--h4);
  font-weight: 700;
  color: var(--color-primary);
  margin: 0 0 10px 0px;
}

.tel-email {
  display: flex;
  width: 100%;
  gap: 10px;

  > div:nth-child(1) {
    flex: 0 0 150px; // This sets the first child to take 50px width and not grow or shrink
  }

  > div:nth-child(2) {
    flex: 1; // This makes the second child take up the remaining width
  }
}

/*-------------------------
    Button
-------------------------*/
.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  padding: 18px 25px;
  height: 20px;
  text-align: center;
  transition: var(--transition);
  position: relative;
  z-index: 1;
  &.form-button-primary {
    background-color: var(--color-primary);
    color: var(--color-white);
    border: 1px solid var(--color-primary);
    overflow: hidden;
    &::after {
      content: "";
      height: 300px;
      width: 300px;
      background-color: var(--color-ship-gray);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) scale(0);
      transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      z-index: -1;
    }
    &:hover {
      background-color: var(--color-ship-gray);
      border-color: var(--color-ship-gray);
      &::after {
        transform: translateY(-50%) translateX(-50%) scale(1);
      }
    }
  }
}
