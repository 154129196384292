$colorTextDark: var(--color-text-dark);
$h6FontSize: var(--h6);
$colorPrimary: var(--color-primary);

.history-sections {
  color: $colorTextDark;
  width: 100%;

  .history-section-establishment {
    width: 100%;

    .width-1200 {
      margin: auto;
      width: 100%;
      padding: 10px;
      max-width: 1200px;

      .history-intro {
        padding: 20px 0;
        p {
          font-size: $h6FontSize;
          text-align: center;
          margin-bottom: 0;
        }
      }

      .home-textiles {
        display: flex;
        align-items: center;
        .heading {
          padding: 40px;
          width: 200px;
          min-width: 300px;
          text-align: right;

          border-right: 1px solid #000;

          h4 {
            font-size: var(--h4);
            margin: 0;
            color: var(--color-primary);
            font-weight: 700;
          }
        }

        .desc {
          padding: 20px;
          p {
            margin: 0;
          }
        }
      }

      .career-container {
        display: flex;
        gap: 20px;
        width: 100%;

        @media #{$md-layout} {
          flex-wrap: wrap;
          flex-direction: column;
          align-items: center;
        }

        .career-left {
          display: flex;
          flex-direction: column;
          width: 50%;
          gap: 10px;
          @media #{$md-layout} {
            width: 600px;
          }
          @media #{$sm-layout} {
            width: 100%;
            padding: 0 20px;
          }
          .top-container {
            display: flex;
            justify-content: flex-end;
            gap: 20px;

            @media #{$large-mobile} {
              flex-direction: column-reverse;
            }
            .left-container {
              display: flex;
              flex-direction: column;
              max-width: 300px;
              justify-content: flex-start;
              @media #{$md-layout} {
                max-width: none;
              }
              p {
                text-align: right;
                font-size: var(--font-body-3);
                font-weight: 600;
                margin: 0;
              }
            }
            .right-container {
              display: flex;
              flex-direction: column;
              max-width: 200px;
              justify-content: center;
              align-items: center;
              gap: 5px;
              @media #{$large-mobile} {
                width: 100%;
                max-width: none;
              }
              img {
                width: 200px;
                min-width: 200px;
                height: 120px;
                object-fit: cover;
              }
              p {
                font-size: var(--font-body-1);
                color: #de697c;
                font-weight: 600;
                margin: 0;
              }
            }
          }
          .down-container {
            display: flex;
            justify-content: flex-end;

            img {
              max-width: 320px;
              height: 150px;
              @media #{$md-layout} {
                width: 100%;
                max-width: none;
                height: auto;
              }
            }
          }
        }
        .career-right {
          display: flex;
          width: 50%;
          gap: 20px;

          @media #{$md-layout} {
            width: 600px;
          }

          @media #{$sm-layout} {
            width: 100%;
            padding: 0 20px;
          }

          @media #{$large-mobile} {
            flex-direction: column;
          }
          .left-container {
            display: flex;
            flex-direction: column;
            max-width: 200px;
            min-width: 200px;
            justify-content: center;
            align-items: center;
            gap: 5px;
            @media #{$large-mobile} {
              width: 100%;
              max-width: none;
            }
            img {
              width: 200px;
              height: 100%;
              min-height: 280px;
              object-fit: cover;
            }
            p {
              font-size: var(--font-body-1);
              color: #539ebf;
              font-weight: 600;
              margin: 0;
            }
          }
          .right-container {
            display: flex;
            flex-direction: column;
            max-width: 200px;
            justify-content: flex-start;
            @media #{$md-layout} {
              max-width: none;
            }
            p {
              text-align: left;
              font-size: var(--font-body-3);
              font-weight: 600;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .history-section-establishment {
    background-color: #f8f8f8;

    .contianer-row {
      display: flex;
      gap: 40px;
      padding: 40px 0 0 0;
      @media #{$md-layout} {
        flex-direction: column;
      }

      .history-section-heading {
        width: 50%;
        text-align: right;
        @media #{$md-layout} {
          width: 100%;
        }

        h4 {
          color: $colorPrimary;
          font-size: var(--h4);
        }

        p {
          font-size: var(--h6);
          margin-bottom: 0;
        }
      }

      .history-heading-image {
        min-width: 450px;
        width: 50%;
        min-height: 400px;
        overflow: hidden;
        position: relative;
        @media #{$md-layout} {
          width: 100%;
          min-width: auto;
          min-height: auto;
        }

        img {
          border-radius: 40px;
          width: 100%;
          height: 100%;
          max-height: 330px;
          object-fit: cover;
        }
      }
    }

    .history-intro {
      padding: 20px 0;
      p {
        font-size: $h6FontSize;
        text-align: center;
        margin-bottom: 0;
      }
    }

    .history-section-machinery {
      display: flex;
      flex-direction: column;

      h4 {
        color: $colorPrimary;
        text-align: center;
        font-size: var(--h4);
      }

      .history-section-machinery-images {
        display: flex;
        width: 100%;
        min-height: 330px;
        overflow: hidden;
        position: relative;
        gap: 20px;
        @media #{$md-layout} {
          justify-content: center;
          align-items: center;
          flex-flow: wrap;
        }
        @media #{$large-mobile} {
          flex-direction: column;
        }

        .history-section-machinery-images-img {
          width: 100%;
          @media #{$md-layout} {
            width: 48%;
          }
          @media #{$large-mobile} {
            width: 100%;
          }
          img {
            border-radius: 40px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: 330px;
          }
        }

        .width-30,
        .width-20,
        .width-50 {
          width: 100%;

          img {
            border-radius: 40px;
            width: 100%;
            max-width: 570px;
            height: 100%;
            min-height: 330px;
            max-height: 330px;
            object-fit: cover;
          }

          p {
            margin: 0px;
            text-align: center;
            width: fit-content;
            padding: 10px 0 0 0;
            font-size: 22px;
            font-weight: 700;

            border-bottom: 2px;
            border-top: 0;
            border-style: solid;
            border-image: linear-gradient(
                to right,
                #fff,
                var(--color-primary),
                #fff
              )
              1 0%;
          }
        }

        .width-30 {
          width: 30%;
          @media #{$md-layout} {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45%;
          }
          @media #{$large-mobile} {
            width: 100%;
          }
        }
        .width-20 {
          width: 20%;
          @media #{$md-layout} {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45%;
          }
          @media #{$large-mobile} {
            width: 100%;
          }
        }
        .width-50 {
          width: 50%;
          @media #{$md-layout} {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }
          @media #{$large-mobile} {
            width: 100%;
          }
        }
      }
    }
  }
}
