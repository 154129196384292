.historySection {
  width: 100%;
  margin: 80px auto auto auto;
  border-bottom: 1px solid black;
  overflow-x: hidden; /* Enable horizontal scroll */
  overflow-y: hidden; /* Disable vertical scroll */
  // white-space: nowrap;

  .historyBox {
    width: 100%;
    padding: 0px 1rem 0 1rem;
  }

  .sliderItems {
    width: 100%;
    // overflow: hidden;
    color: black;
    cursor: grab;

    .sliderItem {
      //   display: grid;
      //   grid-template-columns: repeat(6, 20.4rem);
      //   gap: 1rem;
      //   padding: 4rem 2rem;
    }
    .sliderItem ::-webkit-scrollbar {
      display: none;
    }
  }
}

.historyItem {
  display: flex;
  flex-direction: row;

  .historyBorder {
    .historyVertical-line {
      margin-left: 5px;
      height: 100%;
      width: 1px;
      background-color: var(--color-black);
    }

    .historyCircle {
      width: 10px;
      height: 10px;
      background-color: var(--color-black);
      border-radius: 50%;
    }
  }
  .historyContent {
    padding: 0px 20px;
    .historyContentIcon {
      margin: 0;
      font-size: 70px;
      height: 80px;
      svg {
        vertical-align: top;
      }
    }
    .historyContentYear {
      font-size: 24px;
    }
    .historyContentDesc {
      text-align: justify;
      padding-bottom: 30px;
    }
  }
}

.processSection {
  width: 100%;
  margin: 80px auto auto auto;
  // border-bottom: 1px solid black;

  .processBox {
    width: 100%;
    padding: 0px 1rem 0 1rem;
  }

  .sliderItems {
    width: 100%;
    overflow: hidden;
    color: black;
    cursor: grab;

    .sliderItem {
      //   display: grid;
      //   grid-template-columns: repeat(6, 20.4rem);
      //   gap: 1rem;
      //   padding: 4rem 2rem;
    }
    .sliderItem ::-webkit-scrollbar {
      display: none;
    }
  }
}

.processItem {
  display: flex;
  flex-direction: row;
  height: 100%;

  .processBorder {
    .processVertical-line {
      margin-left: 7.5px;
      height: 100%;
      width: 1px;
      background-color: var(--color-black);
    }

    .processCircle {
      width: 15px;
      height: 15px;
      background-color: var(--color-white);
      border: 1px solid;
      border-radius: 50%;
    }
  }
  .processContent {
    padding: 0px 20px;
    .processContentIcon {
      margin: 0;
      font-size: 70px;
      height: 80px;
      color: var(--color-primary);
    }
    .processContentYear {
      font-size: 24px;
    }
    .processContentDesc {
      text-align: justify;

    }
  }
}
