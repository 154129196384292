.intro-wrapper {
  width: 100%;
  color: var(--color-text-dark);
  .intro-container {
    margin: auto;
    width: 100%;
    padding: 10px;
    max-width: 1150px;
    .intro {
      padding: 20px 0;
      p {
        font-size: var(--h6);;
        text-align: center;
        margin-bottom: 0;
        color: var(--color-text-dark);
      }
    }
  }
}
