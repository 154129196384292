.product-intro-wrapper {
  width: 100%;
  color: var(--color-text-dark);
  .product-intro-container {
    margin: auto;
    width: 100%;
    padding: 10px;
    max-width: 1150px;
    .product-intro {
      padding: 20px 0;
      h4{
        margin: 0 0 10px 0 ;
        color: var(--color-primary);
        font-weight: 600;
        text-align: center;
      }
      p {
        font-size: var(--h6);
        text-align: center;
        margin-bottom: 0;
        color: var(--color-text-dark);
      }
    }
  }
}
