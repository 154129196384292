.job-post-container {
  display: flex;
  width: 100%;
  justify-content: center;
  .job-post {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1000px;
    gap: 20px;

    @media #{$md-layout} {
      justify-content: center;
    }
    .post {
      width: calc(50% - 10px);
      min-width: 400px;
      display: flex;
      gap: 20px;

      @media #{$md-layout} {
        width: 100%;
        min-width: auto;
        padding: 0 50px;
      }

      @media #{$large-mobile} {
        flex-direction: column;
        gap: 10px;
        width: 100%;
        min-width: auto;
        padding: 0 20px;
      }

      .post-image {
        width: 180px;
        min-width: 180px;
        height: 180px;
        @media #{$large-mobile} {
            width: 100%;
            min-width: none;
            height: auto;
          }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 40px;
        }
      }
      .post-content {
        color: var(--color-text-dark);
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        h5 {
          margin: 0;
          width: fit-content;
          font-weight: 600;
          border-bottom: 1px;
          border-top: 0;
          border-style: solid;
          border-image: linear-gradient(
              to right,
              #fff,
              var(--color-primary),
              #fff
            )
            1 0%;
        }
        p {
          margin: 0;
        }
      }
    }
  }
}
