.CTASection {
  color: var(--color-black);
  background-color: #f9f9f9;
  padding: 4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .CTAContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-direction: row;
    width: 100%;

    @media #{$sm-layout} {
      flex-direction: column;
    }

    .CTAImage {
      @media #{$md-layout} {
        width: 60%;
      }
      @media #{$sm-layout} {
        width: 100%;
      }

      img {
        max-height: 350px;
        object-fit: cover;
        border-radius: 40px;
      }
    }
    .CTAContent {
      min-width: 300px;

      @media #{$md-layout} {
        width: 40%;
      }
      @media #{$sm-layout} {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      h4 {
        margin: 0px !important;
        font-size: 50px;
        line-height: 1.2 !important;
        color: var(--color-primary) !important;
        @media #{$md-layout} {
          font-size: 2rem;
        }
      }
      h1 {
        margin: 0px !important;
        font-size: 100px !important;
        line-height: 0.8 !important;
        color: var(--color-primary) !important;
        @media #{$md-layout} {
          font-size: 4rem !important;
        }
      }
      h5 {
        margin: 0px !important;
        font-size: 40px;
        line-height: 1.2 !important;
        color: var(--color-primary) !important;
        @media #{$md-layout} {
          font-size: 1rem;
        }
      }
      p {
        margin: 0px !important;
      }
    }
  }
}
