$colorTextDark: var(--color-text-dark);
$h6FontSize: var(--h6);
$colorPrimary: var(--color-primary);

.gallery-section {
  color: $colorTextDark;
  width: 100%;

  .gallery-section-introduction {
    width: 100%;

    .gallery-container {
      margin: auto;
      width: 100%;
      padding: 10px;
      max-width: 1200px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .gallery-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;

        .gallery-card {
          width: 270px;
          min-width: 270px;
          max-width: 270px;
          height: 220px;
          max-height: 220px;
          max-height: 220px;
          text-align: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 40px;
          }

          h4 {
            color: $colorPrimary;
            margin: 0px;
          }

          p {
            margin: 0px;
          }
        }
      }

      .gallery2-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 60px;
        justify-content: center;

        .gallery2-card {
          width: 270px;
          min-width: 270px;
          max-width: 270px;
          height: 370px;
          text-align: center;

          img {
            width: 100%;
            max-height: 310px;
            height: 100%;
            object-fit: cover;
            border-radius: 40px;
          }

          h4 {
            color: $colorPrimary;
            margin: 10px 0 10px 0;
            font-weight: 600;
          }
        }
      }

      .gallery3-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
        position: relative;

        .gallery3-card {
          width: 270px;
          min-width: 270px;
          max-width: 270px;
          height: 400px;
          text-align: center;
          position: relative;
          overflow: hidden;
        
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: relative;
          }
        
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
            border-radius: 40px;
          }

          .gallery3-card-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            width: 270px;
            z-index: 2;
            h4 {
              margin: 10px 0 10px 0;
              font-weight: 600;
              text-align: center;
              font-size: var(--h5);
            }
            p{
              margin: 0px;
              padding: 0 10px 10px 10px;
            }
          }
        }
      }
    }
  }
}
