/*---------------------------------------------
Template Name: Abstrak
Template URL: https://new.axilthemes.com/demo/react/abstrak/
Description: Digital Agency React JS Template.
Author: Axilthemes
Author URL: https://axilthemes.com/
Version: 1.1.0

===============================================   
STYLESHEET INDEXING
|
|___ Default Styles
|	|___ Variables
|	|___ Reset Styels
|	|___ Typography
|	|___ Forms Styles
|	|___ Helper Classes 
|
|___ Elements Styles
|	|___ Back To Top
|	|___ Breadcrumb
|	|___ Button
|	|___ Pagination 
|	|___ Section Heading
|	|___ Slick 
|
|___Template Styles
|	|___ About Us
|	|___ Banner
|	|___ Brand
|	|___ Call To Action
|	|___ Case Study
|	|___ Contact
|	|___ Counter Up
|	|___ FAQ
|	|___ Pricing
|	|___ Process
|	|___ Project
|	|___ Service
|	|___ Team
|	|___ Testimonial
|	|___  Why Choose
|	|___  404 Error
|
|___Blocks Styles
|	|___ Header Styles
|	|___ Blog Styles
|	|___ Footer Styles
|
|___Dark Style
|
|___Spacing
|
|___RTL
|
|___ END STYLESHEET INDEXING

--------------------------------------------*/

/*=======================================================================
1. Default Styles
=========================================================================*/
@import "default/variable";
@import "default/reset";
@import "default/typography";
@import "default/form";
@import "default/classes";

/*=======================================================================
2. Elements Styles
=========================================================================*/
@import "elements/animate";
@import "elements/back-top";
@import "elements/breadcrumb";
@import "elements/button";
@import "elements/pagination";
@import "elements/section-heading";
@import "elements/slick";

/*=======================================================================
3. Template Styles
=========================================================================*/
@import "template/about";
@import "template/banner";
@import "template/brand";
@import "template/call-to-action";
@import "template/case-study";
@import "template/contact";
@import "template/counterup";
@import "template/faq";
@import "template/pricing";
@import "template/process";
@import "template/project";
@import "template/service";
@import "template/team";
@import "template/testimonial";
@import "template/why-choose";
@import "template/common-pages";
@import "template/splash.scss";
@import "template/onepage";
@import "template/_certificate";
@import "template/_cta";
@import "template/_timeline";
@import "template/_contact-us";
@import "template/_slide";
@import "template/_introduction";
@import "template/_gallery";
@import "template/_products";
@import "template/_jobpost";
@import "page/history";
@import "page/leadership";
@import "page/awards";
@import "page/contactUs";

/*=======================================================================
4.Blocks Styles 
=========================================================================*/
@import "header/header";
@import "blog/blog";
@import "blog/widget";
@import "footer/footer";
@import "footer/_footerThree";

/*=======================================================================
5. Dark Style 
=========================================================================*/
@import "dark-style/dark-version";

/*=======================================================================
6. Spacing 
=========================================================================*/
@import "default/spacing";

/*=======================================================================
7. RTL 
=========================================================================*/
// @import "rtl/rtl";

.Loader-Custom {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background-color: white;
}
