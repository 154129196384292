$colorTextDark: var(--color-text-dark);
$h6FontSize: var(--h6);
$colorPrimary: var(--color-primary);

.leadearship-sections {
  color: $colorTextDark;
  width: 100%;
  display: flex;

  .leadership-cards {
    margin: auto;
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    align-items: center;

    .leadership-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 300px;
      min-height: 320px;
      text-align: center;

      img {
        width: 100%;
        height: 100%;
        max-height: 330px;
        object-fit: cover;
        border-radius: 40px;
      }

      h4 {
        color: $colorPrimary;
        margin: 0px;
      }

      p {
        margin: 0px;
      }
    }
  }
}
