.certificate-wrapper {
  background-color: #f7f7f7;
  width: 100%;
}
.certificates {
  max-width: 1400px;
  margin: 0 auto;
  padding: 3rem 0;

  h2 {
    padding: 0 40px;
    font-size: 24px;
    text-transform: uppercase;
    position: relative;
    width: fit-content;
  }

  h2::after {
    content: "";
    position: absolute;
    bottom: 0;
    margin-bottom: -4px;
    left: 40px;
    width: 15%;
    height: 2px; /* You can adjust the height of the border as needed */
    background-color: #000; /* Set the color of the border */
  }
}

.certificate-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.certificate-img {
  max-height: 75px;
}
