.contactContainer {
  width: 100%;
  display: flex;
  margin: 0px;
  padding: 0px;

  @media #{$sm-layout} {
    flex-direction: column;
  }

  .contactHeading {
    background-color: #da9351;
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    @media #{$sm-layout} {
      width: 100%;
    }

    .contactImage {
      width: 60%;
      position: relative;
      height: 100%;
      display: block;

      @media #{$sm-layout} {
        display: none;
      }

      img {
        min-width: 700px;
        width: 100%;
        position: absolute;
        right: 0px;
      }
    }
    .contactTitle {
      width: 40%;
      min-width: 275px;
      text-align: end;
      font-size: 70px;
      line-height: 1;

      @media #{$sm-layout} {
        width: 100%;
        min-width: none;
        text-align: center;
        font-size: 40px;
      }
    }
  }
  .contactForm {
    background-color: #232323;
    width: 45%;
    padding: 50px 20px 30px 20px;

    @media #{$sm-layout} {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .contactFormContainer {
      max-width: 500px;

      @media #{$sm-layout} {
        width: 100%;
      }
    }
  }
}

.btn-height {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px !important;
  justify-self: flex-end;
  width: 150px !important;
}

.align-end {
  display: flex;
  justify-content: end;
  margin-bottom: 0px !important;
}
